const exerciseList = [
    'Push-ups',
    'Squats',
    'Lunges',
    'Plank',
    'Burpees',
    'Sit-ups',
    'Mountain climbers',
    'Jumping jacks',
    'Deadlifts',
    'Bicep curls',
    'Tricep dips',
    'Shoulder press',
    'Bench press',
    'Leg press',
    'Leg curls',
    'Calf raises',
    'Russian twists',
    'Bicycle crunches',
    'Crunches',
    'Flutter kicks',
    'Running',
    'Cycling',
    'Swimming',
    'Rowing',
    'Jump rope',
    'High knees',
    'Side lunges',
    'Glute bridges',
    'Dumbbell rows',
    'Lat pulldowns',
    'Pull-ups',
    'Yoga',
    'Zumba',
    'Water Aerobics',
    'Supermans',
    'Stair Runs',
    'Pilates',
    'Boxing',
    'Dynamic Stretching',
    'Elliptical',
    'Kettlebell swings',
    'Box jumps',
    'Battle ropes',
    'Dumbbell lunges',
    'Medicine ball slams',
    'TRX exercises',
    'Plyometric exercises',
    'Agility ladder drills',
    'Step-ups',
    'Wall sits'
];
  
export default exerciseList;