function Loading () {


    return (
        <>
            <div>
                <img className="loading" src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"></img>
            </div>
        </>
    )
}

export default Loading;
